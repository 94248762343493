import React, { useState, useEffect } from 'react';
import '../styles/compaign.css';

function Compaign({ abbreviation, dealId, initiative }) {
  const [channel, setChannel] = useState('');
  const [compaignCount, setCompaignCount] = useState('');
  const [platform, setPlatform] = useState('');
  const [axiOptimization, setAxiOptimization] = useState(false);
  const [addedValue, setAddedValue] = useState(false);
  const [breakoutPurpose1, setBreakoutPurpose1] = useState('');
  const [breakoutPurpose2, setBreakoutPurpose2] = useState('');

  const [campaignNames, setCampaignNames] = useState(() => {
    const savedCampaignNames = localStorage.getItem('campaignNames');
    return savedCampaignNames ? JSON.parse(savedCampaignNames) : [];
  });

  useEffect(() => {
    localStorage.setItem('campaignNames', JSON.stringify(campaignNames));
  }, [campaignNames]);

  const handleSubmit = (event) => {
    event.preventDefault();

    let newCampaignName = `${abbreviation} | ${initiative}`;
    
    // if (platform) {
    //   newCampaignName += ` | ${platform}`;
    // }

    // if (axiOptimization) {
    //   newCampaignName += `, AXI-O`;
    // }
    // if (breakoutPurpose1) {
    //   newCampaignName += `, ${breakoutPurpose1}`;
    // }

    // if (breakoutPurpose2) {
    //   newCampaignName += `, ${breakoutPurpose2}`;
    // }

    // if (addedValue) {
    //   newCampaignName += `, ADDED VALUE`;
    // }
    let contexts = [];

    if (platform) {
      contexts.push(platform);
    }
    if (axiOptimization) {
      contexts.push("AXI-O");
    }
    if (breakoutPurpose1) {
      contexts.push(breakoutPurpose1);
    }
    if (breakoutPurpose2) {
      contexts.push(breakoutPurpose2);
    }
    if (addedValue) {
      contexts.push(`ADDED VALUE`);
    }
    if (contexts.length > 0) {
      newCampaignName += ` | ${contexts.join(', ')}`;
      newCampaignName += `, ${channel} | ${dealId}${compaignCount}`;
    }
    else{
      newCampaignName += ` | `;
      newCampaignName += `${channel} | ${dealId}${compaignCount}`;
    }


    setCampaignNames([...campaignNames, newCampaignName]);
  };
  const ExtractDetailsFromUploadedFile = async (event) => {
    event.preventDefault();

    const file = event.target.files[0];
    if (!file) return;

    if (file.type !== 'text/csv') {
        alert('Please upload a valid .csv file.');
        event.target.value = ''; // Reset the file input
        return;
    }

    const data = await file.text();
    const lines = data.split('\n');

    // Assuming the first line is the header
    const header = lines[0].split(',').map(h => h.replace(/\r$/, '').trim());

    // Get the indices of the required columns
    const channelIndex = header.indexOf('Channel');
    const campaignCountIndex = header.indexOf('Campaign Number');
    const platformIndex = header.indexOf('Platform');
    const axiOptimizationIndex = header.indexOf('AXI-Optimization');
    const addedValueIndex = header.indexOf('Added Value');
    const breakoutPurpose1Index = header.indexOf('Breakout Purpose 1');
    const breakoutPurpose2Index = header.indexOf('Breakout Purpose 2');

    if (channelIndex === -1 || campaignCountIndex === -1) {
        alert('Required columns not found in the uploaded file.');
        event.target.value = ''; // Reset the file input
        return;
    }

    const newCampaignNames = [];

    // Start from line 1 to skip header
    for (let i = 1; i < lines.length; i++) {
        const row = lines[i].split(',').map(r => r.trim());

        // Extract required fields
        let channel = row[channelIndex] || '';
        let campaignCount = row[campaignCountIndex] || '';

        // Skip row if either required field is missing
        if (!channel || !campaignCount) {
            continue; // Skip this row if required fields are missing
        }

        // Optional fields
        let platform = row[platformIndex] || '';
        let axiOptimization = row[axiOptimizationIndex] || '';
        let addedValue = row[addedValueIndex] || '';
        let breakoutPurpose1 = row[breakoutPurpose1Index] || '';
        let breakoutPurpose2 = row[breakoutPurpose2Index] || '';

        // Remove any trailing \r
        channel = channel.replace(/\r$/, '');
        campaignCount = campaignCount.replace(/\r$/, '');
        platform = platform.replace(/\r$/, '');
        axiOptimization = axiOptimization.replace(/\r$/, '');
        addedValue = addedValue.replace(/\r$/, '');
        breakoutPurpose1 = breakoutPurpose1.replace(/\r$/, '');
        breakoutPurpose2 = breakoutPurpose2.replace(/\r$/, '');

        // Start constructing the campaign name
        let newCampaignName = `${abbreviation} | ${initiative}`;
        let contexts = [];

        // Add optional fields to contexts
        if (platform) contexts.push(platform);
        if (axiOptimization) contexts.push("AXI-O");
        if (breakoutPurpose1) contexts.push(breakoutPurpose1);
        if (breakoutPurpose2) contexts.push(breakoutPurpose2);
        if (addedValue) contexts.push("ADDED VALUE");

        // If there are any contexts, append them
        if (contexts.length > 0) {
            newCampaignName += ` | ${contexts.join(', ')}`;
            newCampaignName+=", "
        } else {
            newCampaignName += ` | `;
        }

        // Append the channel and campaign number
        newCampaignName += `${channel} | ${dealId}${campaignCount}`;

        // Add the new campaign name to the list
        newCampaignNames.push(newCampaignName);
    }

    // Update the campaign names state
    setCampaignNames([...campaignNames, ...newCampaignNames]);
  };


  const handleClear = () => {
    setChannel('');
    setCompaignCount('');
    setPlatform('');
    setAxiOptimization(false);
    setAddedValue(false);
    setBreakoutPurpose1('');
    setBreakoutPurpose2('');
  };

  const handleRemove = (index) => {
    const updatedCampaignNames = [...campaignNames];
    updatedCampaignNames.splice(index, 1);
    setCampaignNames(updatedCampaignNames);
  };

  const downloadCSV = () => {
    const csvContent = `data:text/csv;charset=utf-8,${campaignNames.map(name => `"${name}"`).join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'campaigns.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleChannelChange = (e) => {
    setChannel(e.target.value);
    if (e.target.value !== 'AXSS Social') {
      setPlatform(''); // Clear the platform if the channel is not AXSS Social
    }
  };

  return (
    <>
      <div className="form-container">
        <form className="compaign-form" onSubmit={handleSubmit}>
          <div className="options-container">
            <div className="channel">
              <div className="channel-container">
                <span>*</span>
                <label htmlFor="Channel" className='input-label'>Channel:</label>
                <select id='Channel' name="Channel" value={channel} onChange={handleChannelChange}>
                  <option value="" disabled>Select Channel</option>
                  <option value="Video">Video</option>
                  <option value="DOOH">DOOH</option>
                  <option value="Cable">Cable</option>
                  <option value="AXSS Social">AXSS Social</option>
                  <option value="AXSS Search">AXSS Search</option>
                  <option value="AXSS PMax">AXSS PMax</option>
                  <option value="Native">Native</option>
                  <option value="Display">Display</option>
                  <option value="AXVS">AXVS</option>
                  <option value="High Impact">High Impact</option>
                  <option value="Takeover">Takeover</option>
                  <option value="YouTube">YouTube</option>
                  <option value="CTV">CTV</option>
                  <option value="Audio">Audio</option>
                  <option value="Spaceback">Spaceback</option>
                </select>
              </div>
            </div>
            <div className="campaign-number">
              <span>*</span>
              <label htmlFor="campaign-number" className='input-label'>Campaign Number:</label>
              <input
                type="number"
                id="campaign-number"
                placeholder="Campaign Count"
                name="campaignCount"
                value={compaignCount}
                onChange={(e) => setCompaignCount(e.target.value)}
                min="100"
                required
              />
            </div>
            <div className="platform-container">
              <div className="pl-container">
                <div className="aestrick">
                  {channel === 'AXSS Social' && <span>*</span>}
                  <label htmlFor="platform" className='input-label'>Platform:</label>
                  <select
                    id='platform'
                    name="Platform"
                    value={platform}
                    onChange={(e) => setPlatform(e.target.value)}
                    required={channel === 'AXSS Social'}
                  >
                    <option value="" disabled>Select Platform</option>
                    <option value="FB">FB</option>
                    <option value="IG">IG</option>
                    <option value="FB/IG">FB/IG</option>
                    <option value="TikTok">TikTok</option>
                    <option value="Twitch">Twitch</option>
                    <option value="Google">Google</option>
                    <option value="Bing">Bing</option>
                    <option value="Pinterest">Pinterest</option>
                  </select>
                </div>
                <span>Recommended for Social Campaigns</span>
              </div>
            </div>
          </div>

          <div className="optional-options">
            <h5>OPTIONAL</h5>
            <div className="optional-options-container">
              <div className="optimization">
                <label htmlFor="axiOptimization" className='input-label'>AXI-Optimization</label>
                <input
                  type="checkbox"
                  id="axiOptimization"
                  checked={axiOptimization}
                  onChange={(e) => setAxiOptimization(e.target.checked)}
                />
              </div>
              <div className="added-value">
                <label htmlFor="addedValue" className='input-label'>Added Value</label>
                <input
                  type="checkbox"
                  id="addedValue"
                  checked={addedValue}
                  onChange={(e) => setAddedValue(e.target.checked)}
                />
              </div>
              <div className='breakout'>
              <label htmlFor="breakoutPurpose1" className='input-label'>Breakout Purpose 1:</label>
              <input
                type="text"
                id="breakoutPurpose1"
                placeholder="Breakout Purpose 1"
                value={breakoutPurpose1}
                onChange={(e) => setBreakoutPurpose1(e.target.value)}
              />
              </div>
              <div>
              <label htmlFor="breakoutPurpose2" className='input-label'>Breakout Purpose 2:</label>
              <input
                type="text"
                id="breakoutPurpose2"
                placeholder="Breakout Purpose 2"
                value={breakoutPurpose2}
                onChange={(e) => setBreakoutPurpose2(e.target.value)}
              />
              </div>
            </div>
          </div>
          <div className="buttons">
            <button className="button" onClick={handleClear} id="clear">Clear Tab</button>
            <button className="button">Submit</button>
          </div>
        </form>
      </div>
      <div className="button">
        <div>
          <label htmlFor="uploadCSV" className="upload-button">Upload CSV</label>
          <input 
            type="file" 
            accept=".csv"
            onChange={ExtractDetailsFromUploadedFile} 
            className="upload-csv"
          />
        </div>
      </div>
      <br></br>
      <div className="campaigns-container">
        {campaignNames.length > 0 && <h5 className="rendered-heading">Campaigns</h5>}
        <ul className="list-container">
          {campaignNames.map((name, index) => (
            <div className="single-list" key={index}>
              <li>{name}</li>
              <span className="cross-icon" onClick={() => handleRemove(index)}>
                <i className="fa-solid fa-circle-xmark"></i>
              </span>
              <br />
            </div>
          ))}
        </ul>
        <div className="button">
          {campaignNames.length > 0 && <button className="download-button" onClick={downloadCSV}>CSV</button>}
        </div>
      </div>
    </>
  );
}

export default Compaign;
