import React, { useState, useEffect } from 'react';
import '../styles/deals.css';


function Deals({ dealId, initiative }) {
  const [agency, setAgency] = useState('');
  const [fileNames, setFileNames] = useState(() => {
    // Load initial state from local storage
    const savedFileNames = localStorage.getItem('fileNames');
    return savedFileNames ? JSON.parse(savedFileNames) : [];
  });

  useEffect(() => {
    // Save file names to local storage whenever they change
    localStorage.setItem('fileNames', JSON.stringify(fileNames));
  }, [fileNames]);

  const handleSubmit = (event) => {
    event.preventDefault();

    let newFileName = '';
    if (agency) {
      newFileName = `${agency} | ${initiative} | ${dealId}`;
    } else {
      newFileName = `${initiative} | ${dealId}`;
    }

    setFileNames([...fileNames, newFileName]);
  };
  const ExtractDetailsFromUploadedFile = async (event) => {
    event.preventDefault();

    const file = event.target.files[0];
    if (!file) return;

    if (file.type !== 'text/csv') {
      alert('Please upload a valid .csv file.');
      event.target.value = ''; // Reset the file input
      return;
    }

    const data = await file.text();
    const lines = data.split('\n');

    // Assuming the first line is the header
    const header = lines[0].split(',').map(h => h.replace(/\r$/, '').trim());
    const agencyIndex = header.indexOf('Agency');

    if (agencyIndex === -1) {
        alert('Agency column not found in the uploaded file.');
        event.target.value = ''; // Reset the file input
        return;
    }

    const agencyNames = [];

    // Start from line 1 to skip header
    for (let i = 1; i < lines.length; i++) {
        const row = lines[i].split(',');

        // Skip row if it doesn't have enough columns or if the agency field is missing
        if (row.length <= agencyIndex || !row[agencyIndex]?.trim()) {
            continue;
        }

        let agencyName = row[agencyIndex].trim();

        // Remove any trailing \r
        agencyName = agencyName.replace(/\r$/, '');

        if (agencyName) {
            agencyNames.push(agencyName);
        }
    }

    // Create a new filename for each agency name
    const newFileNames = agencyNames.map((agencyName) =>
        `${agencyName} | ${initiative} | ${dealId}`
    );

    // Add all new filenames to the existing list
    setFileNames([...fileNames, ...newFileNames]);
  };

  const handleClear=()=>{
    setAgency('');
  }

  const handleRemove = (index) => {
    const updatedFileNames = fileNames.filter((_, i) => i !== index);
    setFileNames(updatedFileNames);
  };

  const downloadCSV = () => {
    const csvContent = `data:text/csv;charset=utf-8,${fileNames.map(name => `"${name}"`).join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'campaigns.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};



  return (
    <>
      <div className="form-container">
        <form className="deal-form" onSubmit={handleSubmit}>
          <div className="agency">
            <div><label htmlFor="agency">Agency:</label>
          <input 
            type="text" 
            id='agency'
            placeholder='Agency' 
            name='agency' 
            value={agency}
            onChange={(e) => setAgency(e.target.value)}
          />
          </div>
          <span>If direct, leave Agency blank and click submit</span>
          </div>
          <div className="buttons">
            <button className='button' onClick={handleClear} id='clear'>Clear Tab</button>
            <button className='button'>Submit</button>
          </div>
        </form>
      </div>
      <div className="button">
        <div>
          <label htmlFor="uploadCSV" className="upload-button">Upload CSV</label>
          <input 
            type="file" 
            accept=".csv"
            onChange={ExtractDetailsFromUploadedFile} 
            className="upload-csv"
          />
        </div>
      </div>
      <br></br>
      <div className="deals-container">
        {fileNames.length > 0 && (<div className='heading-container'><h5 className='rendered-heading'>Deal Name</h5><span>* Advertiser and Unique Deal Id will automatically be added to deal name when entered in boostr</span></div>)}
        <ul className="list-container">
          {fileNames.map((fileName, index) => (
            <div className="single-list" key={index}>
              <li>{fileName}</li>
              <span className='cross-icon' onClick={() => handleRemove(index)}>
                <i className="fa-solid fa-circle-xmark"></i>
              </span>
              <br />
            </div>
          ))}
        </ul>
        <div className="button">
          {fileNames.length > 0 && <button className='download-button' onClick={downloadCSV}>CSV</button>}
        </div>
      </div>
    </>
  );
}

export default Deals;
