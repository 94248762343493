import React, { useState } from 'react';
import Deals from './Deals';
import Compaign from './Compaign';
import Adgroup from './Adgroup';
import Creatives from './Creatives';
import Pixels from './Pixels';
import '../styles/home.css';

function Home() {
  const [showRight, setShowRight] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState('Deals'); // Default to 'Deals'
  const [abbreviation, setAbbreviation] = useState('');
  const [dealId, setDealId] = useState('');
  const [initiative, setInitiative] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowRight(true);
  };

  const handleItemClick = (component) => {
    setSelectedComponent(component);
  };
  const handleButtonClick = () => {
    window.open("https://docs.google.com/spreadsheets/d/1UqtLuStbnDuDLT4CRCizOyorYe56ACfwCHo7CBsus2A/edit?gid=288828934#gid=288828934", "_blank");
  };
  const renderComponent = () => {
    const props = { abbreviation, dealId, initiative };
    switch (selectedComponent) {
      case 'Deals':
        return <Deals {...props} />;
      case 'Campaigns':
        return <Compaign {...props} />;
      case 'Adgroups':
        return <Adgroup {...props} />;
      case 'Creatives':
        return <Creatives {...props} />;
      case 'Pixels':
        return <Pixels {...props} />;
      default:
        return <Deals {...props} />;
    }
  };

  return (
    <>
      <div className='main'>
        <div className="left-container">
          <h1 className='logo-heading'>td<span className='logo-x'>X</span></h1>
          <h2 className='name-heading'>GenerX</h2>
          <form id='login-form' className='login-form' onSubmit={handleSubmit}>
            <div className="form-group">
              <div className="label-container">
              <span className="required">*</span>
              <label className='input-label' htmlFor="abbreviation">Advertiser Abbreviation:</label>
              </div>
              <input 
                type="text" 
                id="abbreviation" 
                name="abbreviation" 
                placeholder="Advertiser Abbreviation" 
                maxLength="5" 
                required 
                onInput={(e) => e.target.value = e.target.value.toUpperCase()} 
                pattern="[A-Z0-9]{1,5}" 
                title="Please enter 5 characters or less, all uppercase" 
                value={abbreviation}
                onChange={(e) => setAbbreviation(e.target.value)}
              />
            </div>

            <div className="form-group">
              <div className='label-container'>
              <span className="required">*</span>
              <label className='input-label' htmlFor="dealid">Group Deal Id:</label>
              </div>
              <input 
                id='dealid'
                type="text" 
                name="dealid" 
                placeholder="Group Deal Id" 
                required 
                value={dealId}
                onChange={(e) => setDealId(e.target.value)}
              />
            </div>

            <div className="form-group">
              <div className="label-container">
              <span className="required">*</span>
              <label className='input-label' htmlFor="initiative">Initiative:</label>
              </div>
              <input
                type="text"
                id='initiative'
                name="initiative"
                placeholder="Deal Initiative"
                required
                value={initiative}
                onChange={(e) => setInitiative(e.target.value)}
                title="Avoid including dates such as year, month, or quarter"
                className="initiative-input"
              />
            </div>



            <button type='submit'>Submit</button>
            <button type='submit' onClick={handleButtonClick}>
              Download GenerX Template
            </button>
          </form>
    
        </div>
        {showRight && (
          <div className="right">
            <div className="right-top">
              <div className="genrex">
                <span className='genrex-head name-heading'>GenerX</span>
                <i className="fa-solid fa-circle-arrow-right genrex-head"></i>
                <span className='genrex-head'>{selectedComponent}</span>
              </div>
              <div className="heading">
                <h3 className='name-heading'>Audience<span className='logo-x'>X</span></h3>
              </div>
            </div>
            <div className="right-container">
              <ul>
                <li className={selectedComponent === 'Deals' ? 'active' : ''} onClick={() => handleItemClick('Deals')}>Deals</li>
                <li className={selectedComponent === 'Campaigns' ? 'active' : ''} onClick={() => handleItemClick('Campaigns')}>Campaigns</li>
                <li className={selectedComponent === 'Adgroups' ? 'active' : ''} onClick={() => handleItemClick('Adgroups')}>AD groups</li>
                <li className={selectedComponent === 'Creatives' ? 'active' : ''} onClick={() => handleItemClick('Creatives')}>Creatives</li>
                <li className={selectedComponent === 'Pixels' ? 'active' : ''} onClick={() => handleItemClick('Pixels')}>Pixels</li>
              </ul>
            </div>
            <div className="rendered-component">
              {renderComponent()}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Home;
