import React, { useState, useEffect } from 'react';
import '../styles/adgroup.css';

function Adgroup({ abbreviation, dealId, initiative }) {
  const [audienceDescriptor, setAudienceDescriptor] = useState('');
  const [tactic, setTactic] = useState('');
  const [additionalContext1, setAdditionalContext1] = useState('');
  const [additionalContext2, setAdditionalContext2] = useState('');
  const [additionalContext3, setAdditionalContext3] = useState('');

  const [fileNames, setFileNames] = useState(() => {
    // Load initial state from local storage
    const savedFileNames = localStorage.getItem('adgroupFileNames');
    return savedFileNames ? JSON.parse(savedFileNames) : [];
  });

  useEffect(() => {
    // Save file names to local storage whenever they change
    localStorage.setItem('adgroupFileNames', JSON.stringify(fileNames));
  }, [fileNames]);

  const handleSubmit = (event) => {
    event.preventDefault();

    let newFileName = `${audienceDescriptor} | ${tactic}`;
    let contexts = [];

    if (additionalContext1) {
      contexts.push(additionalContext1);
    }
    if (additionalContext2) {
      contexts.push(additionalContext2);
    }
    if (additionalContext3) {
      contexts.push(additionalContext3);
    }

    if (contexts.length > 0) {
      newFileName += ` | ${contexts.join(', ')}`;
    }
    setFileNames([...fileNames, newFileName]);
  };
  const ExtractDetailsFromUploadedFile = async (event) => {
    event.preventDefault();

    const file = event.target.files[0];
    if (!file) return;

    if (file.type !== 'text/csv') {
        alert('Please upload a valid .csv file.');
        event.target.value = ''; // Reset the file input
        return;
    }

    const data = await file.text();
    const lines = data.split('\n');

    // Assuming the first line is the header
    const header = lines[0].split(',').map(h => h.replace(/\r$/, '').trim());

    // Get the indices of the required columns
    const audienceDescriptorIndex = header.indexOf('Audience Descriptor');
    const tacticIndex = header.indexOf('Tactic');
    const additionalContext1Index = header.indexOf('Additional Context1');
    const additionalContext2Index = header.indexOf('Additional Context2');
    const additionalContext3Index = header.indexOf('Additional Context3');

    if (audienceDescriptorIndex === -1 || tacticIndex === -1) {
        alert('Required columns not found in the uploaded file.');
        event.target.value = ''; // Reset the file input
        return;
    }

    const newFileNames = [];

    // Start from line 1 to skip header
    for (let i = 1; i < lines.length; i++) {
        const row = lines[i].split(',').map(r => r.trim());

        // Extract required fields
        let audienceDescriptor = row[audienceDescriptorIndex] || '';
        let tactic = row[tacticIndex]?.split(' - ')[0] || ''; // Split by " - " and take the first part

        // Skip row if either required field is missing
        if (!audienceDescriptor || !tactic) {
            continue; // Skip this row if required fields are missing
        }

        // Optional fields
        let additionalContext1 = row[additionalContext1Index] || '';
        let additionalContext2 = row[additionalContext2Index] || '';
        let additionalContext3 = row[additionalContext3Index] || '';

        // Remove any trailing \r
        audienceDescriptor = audienceDescriptor.replace(/\r$/, '');
        tactic = tactic.replace(/\r$/, '');
        additionalContext1 = additionalContext1.replace(/\r$/, '');
        additionalContext2 = additionalContext2.replace(/\r$/, '');
        additionalContext3 = additionalContext3.replace(/\r$/, '');

        // Start constructing the file name
        let newFileName = `${audienceDescriptor} | ${tactic}`;
        let contexts = [];

        // Add optional fields to contexts
        if (additionalContext1) contexts.push(additionalContext1);
        if (additionalContext2) contexts.push(additionalContext2);
        if (additionalContext3) contexts.push(additionalContext3);

        // If there are any contexts, append them
        if (contexts.length > 0) {
            newFileName += ` | ${contexts.join(', ')}`;
        }

        // Add the new file name to the list
        newFileNames.push(newFileName);
    }

    // Update the file names state
    setFileNames([...fileNames, ...newFileNames]);
};



  const handleClear = () => {
    // Reset form fields after submission
    setAudienceDescriptor('');
    setTactic('');
    setAdditionalContext1('');
    setAdditionalContext2('');
    setAdditionalContext3('');
  };

  const handleRemove = (index) => {
    const updatedFileNames = fileNames.filter((_, i) => i !== index);
    setFileNames(updatedFileNames);
  };

  const downloadCSV = () => {
    const csvContent = `data:text/csv;charset=utf-8,${fileNames.map(name => `"${name}"`).join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'campaigns.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className="form-container">
        <form className="adgroup-form" onSubmit={handleSubmit}>
          <div className="options-container">
            <div className="main-des">
              <div className="descriptor">
                <span>*</span>
                <label htmlFor="audienceDescriptor" className='input-label'>Audience Descriptor:</label>
                <input 
                  type="text" 
                  placeholder='Audience Descriptor' 
                  name='audienceDescriptor' 
                  id='audienceDescriptor'
                  value={audienceDescriptor}
                  onChange={(e) => setAudienceDescriptor(e.target.value)} 
                  required
                />
              </div>
              <span>Ex. Frequent Shoppers, Purchasers, NonB, AXi-P</span>
            </div>

            <div className="tactic">
              <span>*</span>
              <label className='tactic-label' htmlFor="tactic">Tactic:</label>
              <select 
                name="Tactic" 
                id="tactic" 
                value={tactic}
                onChange={(e) => setTactic(e.target.value)} 
                required
              >
                <option value="" disabled>Select Tactic</option>
                <option value="RT">RT - Retargeting</option>
                <option value="CAT">CAT - Category Contextual</option>
                <option value="BT">BT - Behaviour Targeting</option>
                <option value="LAL">LAL - Lookalike</option>
                <option value="INT">INT - Interest/Affinity (platform native targeting)</option>
                <option value="KW">KW - Keyword Contextual</option>
                <option value="AAL">AAL - Actalike</option>
                <option value="CRM">CRM - CIM lists, 1p data</option>
                <option value="AMP">AMP - Amplifier</option>
                <option value="AFF">AFF - Affinity Targeting</option>
                <option value="SRT">SRT - Search Retargeting</option>
                <option value="GEO">GEO - Geofencing</option>
                <option value="PAT">PAT - PAT Affinity Targeting</option>
                <option value="EVT">EVT - Geo Event Targeting</option>
                <option value="PMP">PMP - Private Market Place (When PMP is the true tactic)</option>
              </select>
            </div>
          </div>
          <div className="optional-options">
            <h5>Optional</h5>
            <div className="optional-options-container">
              <div className="additional-context">
                <label htmlFor="additionalcontext1">Additional Context1:</label>
                <input 
                  type="text" 
                  name='Additional Context1' 
                  placeholder='Additional Context 1' 
                  value={additionalContext1}
                  onChange={(e) => setAdditionalContext1(e.target.value)}
                />
                <span>Ex. IG Feed, Feed+Stories, Dstillery</span>
              </div>
              <div className='option'>
              <label htmlFor="additionalcontext">Additional Context2:</label>
              <input 
                type="text" 
                id='additionalcontext2'
                name='Additional Context2' 
                placeholder='Additional Context 2' 
                value={additionalContext2}
                onChange={(e) => setAdditionalContext2(e.target.value)}
              />
              </div>
              <div className='option'>
                <label htmlFor="additionalcontext3">Additional Context3:</label>
              <input 
                type="text" 
                id='additionalcontext3'
                name='Additional Context3' 
                placeholder='Additional Context 3' 
                value={additionalContext3}
                onChange={(e) => setAdditionalContext3(e.target.value)}
              />
              </div>
            </div>
          </div>
          <div className="buttons">
            <button className='button' onClick={handleClear} id='clear'>Clear Tab</button>
            <button className='button'>Submit</button>
          </div>
        </form>
      </div>
      <div className="button">
        <div>
          <label htmlFor="uploadCSV" className="upload-button">Upload CSV</label>
          <input 
            type="file" 
            accept=".csv"
            onChange={ExtractDetailsFromUploadedFile} 
            className="upload-csv"
          />
        </div>
      </div>
      <br></br>
      <div className="deals-container">
        {fileNames.length > 0 && <h5 className='rendered-heading'>Ad Group Name</h5>}
        <ul className="list-container">
          {fileNames.map((fileName, index) => (
            <div className="single-list" key={index}>
              <li>{fileName}</li>
              <span className='cross-icon' onClick={() => handleRemove(index)}>
                <i className="fa-solid fa-circle-xmark"></i>
              </span>
              <br />
            </div>
          ))}
        </ul>
        <div className="button">
          {fileNames.length > 0 && <button className='download-button' onClick={downloadCSV}>CSV</button>}
        </div>
      </div> 
    </>
  );
}

export default Adgroup;
