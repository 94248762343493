import React, { useState, useEffect } from 'react';
import '../styles/pixels.css';

function Pixels({ abbreviation, dealId, initiative }) {
  const [pixels, setPixels] = useState(() => {
    // Load initial state from local storage
    const savedPixels = localStorage.getItem('pixelsData');
    return savedPixels ? JSON.parse(savedPixels) : [];
  });
  const [descriptor, setDescriptor] = useState('');
  const [pixelType, setPixelType] = useState('');
  const [priority, setPriority] = useState('');

  useEffect(() => {
    // Save pixels to local storage whenever they change
    localStorage.setItem('pixelsData', JSON.stringify(pixels));
  }, [pixels]);


  const handleSubmit = (e) => {
    e.preventDefault();
    if (descriptor && pixelType && priority) {
      const priorityMap = {
        primary: 'pr1',
        secondary: 'pr2',
        tertiary: 'pr3',
        quaternary: 'pr4',
        quinary: 'pr5',
      };
  
      const priorityAbbr = priorityMap[priority] || 'na';
      const newPixel = `${abbreviation} | ${descriptor} | ${pixelType} | ${priorityAbbr}`;
      setPixels([...pixels, newPixel]);
    }
  };
  const ExtractDetailsFromUploadedFile = async (event) => {
    event.preventDefault();

    const file = event.target.files[0];
    if (!file) return;

    if (file.type !== 'text/csv') {
      alert('Please upload a valid .csv file.');
      event.target.value = ''; // Reset the file input
      return;
    }

    const data = await file.text();
    const lines = data.split('\n');

    // Assuming the first line is the header
    const header = lines[0].split(',').map(h => h.replace(/\r$/, '').trim());

    // Get the indices of the required columns
    const descriptorIndex = header.indexOf('Descriptor');
    const pixelTypeIndex = header.indexOf('Pixel Type');
    const priorityIndex = header.indexOf('Pixel Priority');

    if (descriptorIndex === -1 || pixelTypeIndex === -1 || priorityIndex === -1) {
        alert('Required columns not found in the uploaded file.');
        event.target.value = ''; // Reset the file input
        return;
    }

    const newPixels = [];

    // Start from line 1 to skip header
    for (let i = 1; i < lines.length; i++) {
        const row = lines[i].split(',');

        // Check if required fields are present and non-empty
        const descriptor = row[descriptorIndex]?.trim() || '';
        const pixelType = row[pixelTypeIndex]?.trim() || '';
        const priority = row[priorityIndex]?.trim() || '';

        if (!descriptor || !pixelType || !priority) {
            // Skip the row if any required field is missing or empty
            continue;
        }

        // Remove any trailing \r
        const cleanDescriptor = descriptor.replace(/\r$/, '');
        const cleanPixelType = pixelType.replace(/\r$/, '');
        const cleanPriority = priority.replace(/\r$/, '');

        const priorityMap = {
            primary: 'pr1',
            secondary: 'pr2',
            tertiary: 'pr3',
            quaternary: 'pr4',
            quinary: 'pr5',
        };

        const priorityAbbr = priorityMap[cleanPriority.toLowerCase()] || 'na'; // Ensure case-insensitivity
        const newPixel = `${abbreviation} | ${cleanDescriptor} | ${cleanPixelType} | ${priorityAbbr}`;
        newPixels.push(newPixel);
    }

    // Add all new pixels to the existing list
    setPixels([...pixels, ...newPixels]);
  };

  const handleClear=()=>{
    setDescriptor('');
    setPixelType('');
    setPriority('');
  }
  
  
  const handleDelete = (index) => {
    setPixels(pixels.filter((_, i) => i !== index));
  };

  const downloadCSV = () => {
    const csvContent = `data:text/csv;charset=utf-8,${pixels.map(name => `"${name}"`).join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'campaigns.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

  return (
    <>
      <div className="form-container">
        <form onSubmit={handleSubmit} className="pixels-form">

          <div className="options-container">
            <div className="description">
              <div className="des-container">
                <span>*</span>
                <label className='input-label' htmlFor="descriptor">Descriptor:</label>
              <input
                type="text"
                id='descriptor'
                placeholder="Pixel Descriptor"
                name="descriptor"
                value={descriptor}
                onChange={(e) => setDescriptor(e.target.value)}
                required
              />
              </div>
              <span>Ex. Product page, Learn More, Find Inventory Button</span>
            </div>
          <div className="type-container">
            <span>*</span>
            <label className='input-label' htmlFor="type">Pixel Type:</label>
            <select
              name="Pixel Type"
              id="type"
              value={pixelType}
              onChange={(e) => setPixelType(e.target.value)}
              required
            >
              <option value="" disabled>
                Pixel Type
              </option>
              <option value="Conversion">Conversion</option>
              <option value="Conversion + Segment">
                Conversion + Segment
              </option>
              <option value="Segment">
                Segment
              </option>
              <option value="Insights">Insights</option>
            </select>
            </div>
          <div className="priority-container">
            <span>*</span>
            <label htmlFor="priority" className='input-label'>Pixel Priority:</label>
            <select
              name="priority"
              id="priority"
              value={priority}
              onChange={(e) => setPriority(e.target.value)}
            >
              <option value="" disabled>
                Pixel Priority
              </option>
              <option value="primary">Primary(1)</option>
              <option value="secondary">Secondary(2)</option>
              <option value="tertiary">Tertiary(3)</option>
              <option value="quaternary">Quaternary(4)</option>
              <option value="quinary">Quinary(5)</option>
              <option value="na">N/A</option>
            </select>
          </div>
          </div>
          <div className="buttons">
            <button className='button' onClick={handleClear} id='clear'>Clear Tab</button>
            <button className='submit-button'>Submit</button>
          </div>
        </form>
      </div>
      <div className="button">
        <div>
          <label htmlFor="uploadCSV" className="upload-button">Upload CSV</label>
          <input 
            type="file" 
            accept=".csv"
            onChange={ExtractDetailsFromUploadedFile} 
            className="upload-csv"
          />
        </div>
      </div>
      <br></br>
      <div className="deals-container">
        {pixels.length > 0 && <h5 className="rendered-heading">Pixel Name</h5>}
        <ul className="list-container">
          {pixels.map((pixel, index) => (
            <div className="single-list" key={index}>
              <li>{pixel}</li>
              <span className="cross-icon" onClick={() => handleDelete(index)}>
                <i className="fa-solid fa-circle-xmark"></i>
              </span>
              <br />
            </div>
          ))}
        </ul>
        <div className="button">
          {pixels.length > 0 && (
            <button className="download-button" onClick={downloadCSV}>
              CSV
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default Pixels;
